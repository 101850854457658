































































































































































































































// 小屏
@media only screen and (max-width: 1025px) {
  .report_content {
    flex-direction: column;
    overflow-y: scroll;
  }
}

// 大屏时
@media only screen and (min-width: 1025px) {
  .report_content {
    overflow: hidden;
  }
  .pratise_content {
    display: flex;
    flex-direction: column;
    .inner_content {
      flex: 2;
      overflow-y: scroll;
    }
  }
}

.outer_page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  .report_content {
    display: flex;
    padding: 30px;
    background-color: white;
    flex: 2;
    #main {
      margin: 0 auto;
    }
    .no_data_box {
      position: relative;
      margin-top: 40px;
    }
  }
}
